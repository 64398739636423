/* You can add global styles to this file, and also import other style files */
.justify-content-md-end {
  justify-content: flex-end!important;
}
.form-group {
  .required {
    color: red;
    font-size: larger;
  }
}
.mt-10 {
  margin-top: 10px;
}
.mt-20 {
  margin-top: 20px;
}
.mt-2 {
  margin-top: 10px;
}
.text-align-right {
  text-align: right;
}
.mr-10 {
  margin-right: 10px;
}
.required {
  color: red;
}

.dayContainer{
  background-color: white;
}

.flatpickr-time {
  background-color: rgb(23,92,255);
  color:white
}
